<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import Multiselect from "vue-multiselect";
// import branchComponent from "@/components/branchComponent.vue";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "คลังรถ (ทุกสาขา)",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader }, // Multiselect
  data() {
    return {
      // tableData: tableData,

      title: "คลังรถ (ทุกสาขา)",
      items: [
        {
          text: "ฝ่ายขาย",
          active: true,
        },
        {
          text: "คลังรถ (ทุกสาขา)",
          active: false,
        },
      ],
      rowsSelect: [],
      loading: false,
      selected: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      arrTest: [],
      rowsRo: [],
      pageOptions: [10, 25, 50, 100],
      isHidden: false,
      branch: "",
      type: "",
      roId: "",
      invId: "",
      serviceRo: [],
      selectMode: "single",
      userBranchId: "",
      filter: {
        prodCode: "",
        prodNameTh: "",
        prodNameEn: "",
        modelNameTh: "",
        modelNameEn: "",
        userBranchId: "",
        stock: "",
      },
      optionBranch: [],
      userBranch: "",
      urlPrint: "",
      filterOn: [],
      rows: [],
      rowsStock: [],
      sortBy: "age",
      sortDesc: false,
      productDST: [],
      stockBalanceDST: [],
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "prodCode",
          sortable: true,
          label: "รหัสสินค้า",
        },
        // {
        //   key: "productNameTh",
        //   sortable: true,
        //   label: "ชื่อสินค้า(ไทย)",
        // },
        {
          key: "productNameEn",
          sortable: true,
          label: "ชื่อสินค้า",
        },
        // {
        //   key: "modelNameTh",
        //   sortable: true,
        //   label: "ชื่อรุ่น(ไทย)",
        // },
        {
          key: "modelNameEn",
          sortable: true,
          label: "ชื่อรุ่น",
        },
        {
          key: "cost",
          sortable: true,
          label: "ราคาทุน",
        },
        {
          key: "price",
          sortable: true,
          label: "ราคา",
        },
        {
          key: "retailPrice",
          sortable: true,
          label: "ราคาปลีก",
        },
        {
          key: "stockNameTh",
          sortable: true,
          label: "คลัง",
        },
        // {
        //   key: "action",
        //   sortable: false,
        //   label: "แก้ไข|ลบ",
        // },
      ],
      overlayFlag: false,
    };
  },
  computed: {},
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.optionBranch = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.filter.userBranchId = user.userAccessBranch[0];
    this.userBranch = arrayBranch;
    // this.totalRows = this.rows.total;
    this.getData();
    // this.getDataStock();
  },
  created() {},
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
    },
    filterProd(prodId) {
      let result;
      this.productDST.forEach((item) => {
        if (item.prodId == prodId) {
          result = item;
        }
      });
      return `${result != null ? result.productCode : "-"}`;
    },
    filterProdNameTh(prodId) {
      let result;
      this.productDST.forEach((item) => {
        if (item.prodId == prodId) {
          result = item;
        }
      });
      return `${result != null ? result.productNameTh : "-"}`;
    },
    filterAmount(prodId) {
      let result;
      prodId.forEach((item) => {
        console.log(item);
        result = item.amount;
        // item.product.forEach((ele) => {
        //   console.log(ele.product);
        //   if (ele.prodId == prodId) {
        //     result = ele;
        //   }
        // });
      });
      return `${result}`;
    },
    getData: function() {
      this.loading = true;
      useNetw
        .get("api/d-stock-vehicle", {})
        .then((response) => {
          this.rows = response.data;
          // this.rows.total = response.data.total;
          this.productDST = response.data.product;
          this.stockBalanceDST = response.data.stockBalance;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
          // this.isHidden = true;
        });
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    onRowSelected(rows) {
      this.selected = rows[0];
      this.alertConfirm(this.selected.roId);
    },
    handleSearch() {
      this.getData();
    },
  },
  middleware: "authentication",
};
</script>
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}

.textconvert {
  /* display:block; */
  /* filter: flipv fliph; */
  /* -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg); */
  /* position: relative; */
  width: 70px;
  writing-mode: vertical-lr;
  /* white-space: nowrap; */
  /* font-size: 12px; */
  /* margin-bottom: 10px; */
  /* height: 100%; */
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <!-- <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        > -->

        <!-- <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12">
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-4">
                    <div class="mb-3 position-relative">
                      <code> * </code> สาขา:
                      <multiselect
                        v-model="filter.userBranchId"
                        label="nameTh"
                        type="search"
                        :options="optionBranch"
                        :show-labels="false"
                        open-direction="bottom"
                        placeholder=""
                        @input="getDataStock"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span>
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4">
                    <div class="mb-3 position-relative">
                      <code> * </code> คลัง:
                      <multiselect
                        v-model="filter.stock"
                        label="nameTh"
                        type="search"
                        :options="rowsStock"
                        :show-labels="false"
                        open-direction="bottom"
                        placeholder=""
                        @input="getData"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span>
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4">
                    <div class="mb-3">
                      รหัสสินค้า:
                      <b-form-input
                        v-model="filter.prodCode"
                        type="search"
                        class="form-control"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4">
                    <div class="mb-3">
                      ชื่อสินค้า(ไทย):
                      <b-form-input
                        v-model="filter.prodNameTh"
                        type="search"
                        class="form-control"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4">
                    <div class="mb-3">
                      ชื่อสินค้า(อังกฤษ):
                      <b-form-input
                        v-model="filter.prodNameEn"
                        type="search"
                        class="form-control"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4">
                    <div class="mb-3">
                      ชื่อรุ่น(ไทย):
                      <b-form-input
                        v-model="filter.modelNameTh"
                        type="search"
                        class="form-control"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4">
                    <div class="mb-3">
                      ชื่อรุ่น(อังกฤษ):
                      <b-form-input
                        v-model="filter.modelNameEn"
                        type="search"
                        class="form-control"
                      ></b-form-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12">
                <b-button
                  class="btn float-end m-1"
                  variant="info"
                  type="submit"
                  @click="handleSearch"
                >
                  <i class="uil-search-alt"></i>
                  ค้นหา
                </b-button>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-sm-12 col-md-2">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    แสดงผล&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      @input="handlePageChange"
                    ></b-form-select
                    >&nbsp;รายการ
                  </label>
                </div>
              </div>
            </div>
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-skeleton-table></b-skeleton-table>
              </template>
              <div class="table-responsive mb-0">
                <b-table
                  :items="rows"
                  :fields="fields"
                  :current-page="1"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  responsive="sm"
                >
                  <template #cell(index)="rows">
                    {{ rows.index + 1 }}
                  </template>
                  <template #cell(status)="rowRo">
                    <span
                      class="badge bg-warning font-size-14"
                      v-if="rowRo.item.status === 'DFT'"
                    >
                      ร่าง
                    </span>
                    <span
                      class="badge bg-info font-size-14"
                      v-if="rowRo.item.status === 'SUBMIT'"
                    >
                      รออนุมัติ
                    </span>
                    <span
                      class="badge bg-success font-size-14"
                      v-if="rowRo.item.status === 'APV'"
                    >
                      ยืนยันออกใบกำกับ
                    </span>
                    <span
                      class="badge bg-danger font-size-14"
                      v-if="rowRo.item.status === 'CAN'"
                    >
                      ยกเลิก
                    </span>
                  </template>
                </b-table>
              </div>
            </b-skeleton-wrapper>
            <div class="row">
              <div class="col">
                <div class="align-items-center">
                  แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
                  {{ this.rows.total }}
                </div>
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows.total"
                      :per-page="perPage"
                      @change="handleChangePage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <div class="card">
          <div class="card-body">
            <div class="row">
              <!-- <div class="col-6">
                <p class="card-title-desc">รายการที่อนุมัติโอนรถข้ามสาขา</p>
              </div> -->
            </div>
            <div class="table-responsive">
              <table class="table">
                <!-- <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col" class="textconvert">รหัสสินค้า</th>
                    <th scope="col">ชื่อสินค้า</th>
                    <th scope="col" class="text-end">ยอดคงเหลือในสต็อก</th>
                  </tr>
                </thead> -->
                <tbody>
                  <!-- <th colspan="10" style="background: #d5d5d5;">
                    <div class="mb-0">
                      สาขา : {{ data.branchName }}
                    </div>
                  </th> -->
                  <!-- <tr v-if="data == ''">
                    <td class="text-center" colspan="10">
                      <h5>ไม่พบรายการสินค้า</h5>
                    </td>
                  </tr> -->
                  <!-- <tr v-for="(pd, index) in rows.product" :key="index">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ pd }}</td>
                  </tr> -->
                  <tr>
                    <th scope="col">รหัส-ชื่อสินค้า</th>
                    <th
                      scope="col"
                      class="textconvert"
                      v-for="(data, index) in rows.stockBalance"
                      :key="index"
                    >
                      {{ data.branchName != null ? data.branchName : "สาขา: (-)"}}
                    </th>
                    <!-- <td scope="col">ชื่อสินค้า</td> -->
                    <!-- <td scope="col" class="text-end">ยอดคงเหลือในสต็อก</td> -->
                  </tr>
                  <tr v-for="(data, index) in rows.product" :key="index">
                    <!-- <td v-for="(element, index) in data.product" :key="index">
                      {{ filterProd(element.prodId) }} -
                      {{ filterProdNameTh(element.prodId) }}
                    </td> -->
                    <td>
                      {{ data.productCode }} - {{ data.productNameTh }}
                    </td>
                    <td v-for="(item, index) in rows.stockBalance" :key="index">
                      {{ filterAmount(item.product) }}
                      <!-- {{ item.branchId }} -->
                    </td>
                    <!-- <td>{{ filterAmount(element.prodId) }}</td> -->
                    <!-- <td class="text-end">
                      {{ element.amount }}
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- </b-overlay> -->
      </div>
    </div>
  </Layout>
</template>
